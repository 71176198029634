import { template as template_f1a087c6b79c433a8635fd75625bc90d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PasskeyLoginButton = template_f1a087c6b79c433a8635fd75625bc90d(`
  <DButton
    @action={{@passkeyLogin}}
    @icon="user"
    @label="login.passkey.name"
    class="btn-social passkey-login-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PasskeyLoginButton;
