import { template as template_c121fd1b4286406db48306c5c79a6f1d } from "@ember/template-compiler";
const FKControlMenuContainer = template_c121fd1b4286406db48306c5c79a6f1d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
