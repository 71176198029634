import { template as template_e60a753a27c2444c89b30b3e1039954f } from "@ember/template-compiler";
const FKText = template_e60a753a27c2444c89b30b3e1039954f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
