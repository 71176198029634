import { template as template_de85a40f20b745f793b3bd0b99496c5b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_de85a40f20b745f793b3bd0b99496c5b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
